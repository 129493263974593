import { useEffect, useState } from 'react';

// set title for component
function useTitle(title = 'Jarvis English', isOverride = false) {
  useEffect(() => {
    if (isOverride) {
      document.title = title;
    } else {
      document.title =
        title !== 'Jarvis English' ? `${title} - Jarvis English` : title;
    }
  }, []);

  return null;
}

export default useTitle;
